<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                CONDO INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step
                step="4"
                complete
                complete-icon="mdi-home-circle"
              >
                COVERAGES
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="6">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="font-weight-bold"
              :class="mobileDevice ? 'px-2' : 'px-8'"
              @submit.prevent="submit"
            >
              <div v-if="!userpresent && !loading">
                <v-row class="justify-center">
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-card elevation="6">
                      <v-card-text>
                        <div class="text-h3 text-center primary--text my-6">
                          In order to continue with the application, please log in or create a Blanket account.
                        </div>
                        <div>{{ loginError }}</div>

                        <v-form ref="form">
                          <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                          >
                            <v-row class="justify-center mb-4">
                              <v-col
                                cols="10"
                                md="8"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Email"
                                  rules="required|email"
                                >
                                  <v-text-field
                                    v-model="user.email"
                                    label="Email"
                                    outlined
                                    append-icon="mdi-email-outline"
                                    :error-messages="errors"
                                    @click="clickedFieldGlobal('Option userEmail', 19, 'Afficiency Survey v1')"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>

                            <v-row class="justify-center mb-4">
                              <v-col
                                cols="10"
                                md="8"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  vid="password"
                                  name="Password"
                                  rules="required"
                                >
                                  <v-text-field
                                    ref="password"
                                    v-model="user.password"
                                    name="password"
                                    label="Password"
                                    outlined
                                    :type="show ? 'text' : 'password'"
                                    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off'"
                                    :error-messages="errors"
                                    @click:append="show = !show"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                            <v-row class="justify-center">
                              <v-col cols="6">
                                <v-btn
                                  class="primary white--text"
                                  block
                                  large
                                  :disabled="invalid"
                                  @keyup.enter="submit"
                                  @click="loginSubmit"
                                >
                                  <span class="login-btn">Log In</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </validation-observer>
                        </v-form>

                        <v-row class="justify-center mt-4">
                          <v-col class="text-center">
                            <span class="message-text">Don't have an account?</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="text-center">
                            <create-account-button
                              :button-text="'Create One For Me using: ' + user.email"
                              :button-classes="['primary--text','font-weight-bold','theme--light','v-btn--text','v-btn']"
                              :large="true"
                              :block="false"
                              :email="user.email"
                              :password="user.password"
                              :text="true"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Coverages
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mb-4">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <p
                    class="plan-title text-center font-weight-bold secondary--text mb-0 pb-0"
                  >
                    ${{ quotePremium }}/yr
                  </p>
                  <p
                    class="text-center text-h3 secondary--text font-weight-bold mb-0 pb-0"
                  >
                    Payment Plans Available
                  </p>
                  <v-divider class="secondary"></v-divider>
                  <div class="text-center">
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        rateChangeDialog = true
                        clickedFieldGlobal(
                          `coverages rateChangeDialog`,
                          94,
                          'Condo Survey v1'
                        )
                      "
                    >Why did my rate change?</a>
                  </div>
                </v-col>
              </v-row>
              <v-dialog
                v-model="rateChangeDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Your preliminary quote may have changed once we factored
                      in discounts and additional information that we collected.
                      If you have questions about this quote, please contact us
                      directly at (855) 209-7824 to speak to one of our licensed
                      agents.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        rateChangeDialog = false
                        clickedFieldGlobal(
                          `coverages rateChangeDialogCloseBtn`,
                          95,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row>
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-cash
                    </v-icon>
                    <p class="secondary--text text-h4 font-weight-bold">
                      Deductible
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      deductibleDialog = true
                      clickedFieldGlobal(
                        `coverages deductibleDialog`,
                        96,
                        'Condo Survey v1'
                      )
                    "
                  >
                    Deductible
                  </a>
                </v-col>
                <v-col
                  v-if="!showOptions"
                  class="d-flex justify-start"
                >
                  <p class="secondary--text mb-0">
                    ${{ quoteDeductible }}
                  </p>
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                >
                  <v-select
                    v-if="showOptions"
                    v-model.number="deductibleSelection"
                    outlined
                    label="Deductible"
                    :items="[250, 500, 1000, 2000]"
                    @click="
                      clickedFieldGlobal(
                        `coverages deductibleSelection`,
                        97,
                        'Condo Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-home
                    </v-icon>
                    <p class="secondary--text text-h4 font-weight-bold">
                      Home
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      dwellDialog = true
                      clickedFieldGlobal(
                        `coverages dwellingDialog`,
                        98,
                        'Condo Survey v1'
                      )
                    "
                  >Dwelling</a>
                </v-col>
                <v-col class="d-flex justify-start">
                  Included
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                >
                  <p class="secondary--text mb-0">
                    ${{ dwellLimit }}
                  </p>
                </v-col>
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                ></v-col>
                <v-col
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      louDialog = true
                      clickedFieldGlobal(
                        `coverages lossOfUseDialog`,
                        99,
                        'Condo Survey v1'
                      )
                    "
                  >Loss Of Use</a>
                </v-col>
                <v-col class="d-flex justify-start">
                  Included
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                >
                  <p class="secondary--text mb-0">
                    ${{ louLimit }}
                  </p>
                </v-col>
                <v-col cols="12"></v-col>
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                ></v-col>
                <v-col
                  cols="4"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      replacementDialog = true
                      clickedFieldGlobal(
                        `coverages replacementDialog`,
                        100,
                        'Condo Survey v1'
                      )
                    "
                  >ACV or Replacement</a>
                </v-col>
                <v-col
                  class="d-flex justify-start"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <span v-if="replacementOrACV === 'Replacement'">
                    ${{ replacementCost }}
                  </span>
                  <span v-if="replacementOrACV === 'ACV'">
                    {{ replacementCost }}
                  </span>
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0"
                  >
                    {{ replacementOrACV }}
                  </p>
                  <v-select
                    v-if="showOptions"
                    v-model="replacementOrACV"
                    outlined
                    label="Replacement or ACV"
                    :items="['Replacement', 'ACV']"
                    @click="
                      clickedFieldGlobal(
                        `coverages replacementOrACV`,
                        101,
                        'Condo Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-television-classic
                    </v-icon>
                    <p class="secondary--text font-weight-bold">
                      Personal <br />
                      Property
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      ppDialog = true
                      clickedFieldGlobal(
                        `coverages personalPropertyDialog`,
                        102,
                        'Condo Survey v1'
                      )
                    "
                  >Personal Property</a>
                </v-col>
                <v-col class="d-flex justify-start">
                  ${{ ppCost }}
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0"
                  >
                    ${{ ppLimit }}
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Amount"
                    rules="required|numeric|min_value:15000|max_value:150000"
                  >
                    <v-text-field
                      v-if="showOptions"
                      v-model.number="ppAmount"
                      outlined
                      label="Personal Property"
                      color="primary"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          `coverages personalPropertyAmount`,
                          103,
                          'Condo Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-medical-bag
                    </v-icon>
                    <p class="secondary--text font-weight-bold">
                      Personal <br />
                      Liability
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      liabilityDialog = true
                      clickedFieldGlobal(
                        `coverages personalLiabilityDialog`,
                        104,
                        'Condo Survey v1'
                      )
                    "
                  >Liability</a>
                </v-col>
                <v-col>
                  <span v-if="plCost !== 'Included'"> $ {{ plCost }} </span>
                  <span v-if="plCost === 'Included'">
                    Included
                  </span>
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0"
                  >
                    ${{ plLimit }}
                  </p>
                  <v-select
                    v-if="showOptions"
                    v-model="plAmount"
                    outlined
                    label="Liability Amount"
                    color="primary"
                    :items="[100000, 300000, 500000]"
                    @click="
                      clickedFieldGlobal(
                        `coverages personalLiabilityAmount`,
                        105,
                        'Condo Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                ></v-col>
                <v-col
                  cols="4"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      mpDialog = true
                      clickedFieldGlobal(
                        `coverages medicalPayment`,
                        106,
                        'Condo Survey v1'
                      )
                    "
                  >Medical Payments</a>
                </v-col>
                <v-col :class="mobileDevice ? '' : 'pb-12'">
                  <span v-if="medpmCost !== 'Included'">
                    $ {{ medpmCost }}
                  </span>
                  <span v-if="medpmCost === 'Included'">
                    Included
                  </span>
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0"
                  >
                    ${{ medpmLimit }}
                  </p>
                  <v-select
                    v-if="showOptions"
                    v-model="medpmAmount"
                    outlined
                    label="Medical Payments Amount"
                    color="primary"
                    :items="[1000, 2000, 5000]"
                    @click="
                      clickedFieldGlobal(
                        `coverages medicalPaymentAmount`,
                        107,
                        'Condo Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row
                v-if="alCost || moldCost"
                class="d-flex align-center"
              >
                <v-col
                  v-if="!mobileDevice"
                  class="d-flex flex-column justify-end"
                  cols="4"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-shield-check
                    </v-icon>
                    <p class="secondary--text text-h4 font-weight-bold">
                      Additional <br />
                      Coverage
                    </p>
                  </div>
                </v-col>
                <v-col
                  v-if="alCost"
                  cols="4"
                  sm="3"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      alDialog = true
                      clickedFieldGlobal(
                        `coverages animalLiabilityDialog`,
                        108,
                        'Condo Survey v1'
                      )
                    "
                  >Animal Liability</a>
                </v-col>
                <v-col v-if="alCost">
                  ${{ alCost }}
                </v-col>

                <v-col
                  v-if="alCost"
                  cols="5"
                  sm="3"
                >
                  COVERAGE
                </v-col>
                <v-col
                  v-if="!mobileDevice && alCost && moldCost"
                  cols="4"
                ></v-col>
                <v-col
                  v-if="moldCost"
                  cols="4"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      moldDialog = true
                      clickedFieldGlobal(
                        `coverages moldDialog`,
                        109,
                        'Condo Survey v1'
                      )
                    "
                  >Mold, Fungus & Rot</a>
                </v-col>
                <v-col
                  v-if="moldCost"
                  :class="mobileDevice ? 'ml-n2' : 'pb-12'"
                >
                  <span v-if="moldCost !== 'Included' && moldCost !== null">
                    $ </span>{{ moldCost }}
                </v-col>
                <v-col
                  v-if="moldCost"
                  cols="5"
                  sm="3"
                  :class="mobileDevice ? '' : 'pb-12'"
                >
                  COVERAGE
                </v-col>
                <!-- <v-col
                  v-if="!mobileDevice && hurCost && moldCost && covgACost && !alCost"
                  cols="4"
                ></v-col>
                <v-col
                  v-if="!mobileDevice && hurCost && moldCost && alCost && covgACost"
                  cols="4"
                ></v-col> -->
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                ></v-col>
                <!-- <v-col
                  v-if="hurCost"
                  cols="4"
                  sm="3"
                >
                  <p
                    class="secondary--text"
                    @click="moldDialog = true"
                  >
                    Hurricane
                  </p>
                </v-col> -->
                <!-- <v-col v-if="hurCost">
                  <span v-if="hurCost !== 'Included'">
                    $ </span>{{ hurCost }}
                </v-col>
                <v-col
                  v-if="hurCost"
                  cols="5"
                  sm="3"
                >
                  COVERAGE
                </v-col> -->
                <v-col
                  v-if="covgACost"
                  cols="4"
                  sm="3"
                >
                  <p class="secondary--text">
                    Cov A Perils
                  </p>
                </v-col>
                <v-col v-if="covgACost">
                  $ {{ covgACost }}
                </v-col>
                <v-col
                  v-if="covgACost"
                  cols="5"
                  sm="3"
                >
                  Special Covg
                </v-col>
              </v-row>
              <v-row v-if="alCost || moldCost">
                <v-col>
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <div v-if="discounts.length > 0 || maxDiscount">
                <v-row
                  class="d-flex align-center"
                  :class="mobileDevice ? 'mt-4' : 'mt-6'"
                >
                  <v-col
                    cols="4"
                    class="d-flex flex-column justify-end"
                  >
                    <div class="text-center">
                      <v-icon
                        x-large
                        color="orange"
                      >
                        mdi-piggy-bank-outline
                      </v-icon>
                      <p class="orange--text text-h4 font-weight-bold">
                        Discounts
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                    md="4"
                    lg="4"
                    class="d-flex flex-column justify-start"
                  >
                    <div
                      v-if="maxDiscount"
                      class="d-inline-flex pb-2 orange--text"
                    >
                      Max Discount Applied
                    </div>
                    <div>
                      <div
                        v-for="(discount, index) in discounts"
                        :key="index"
                        class="pb-2 orange--text"
                      >
                        <span class="orange--text">{{
                          discount.CoverageCd[0]
                        }}</span>
                        <br />
                        <span class="orange--text">{{
                          discount.CurrentTermAmt[0].Amt[0]
                        }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="!mobileDevice"
                    cols="6"
                    sm="3"
                    md="3"
                    lg="3"
                  >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider class="primary" />
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="!mobileDevice">
                <v-col class="d-flex justify-center align-center">
                  <div>
                    <!-- <v-btn
                      v-if="!showOptions"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      @click="
                        customize();
                        clickedFieldGlobal(
                          'Customize Your Quote',
                          32,
                          'Condo Survey v1'
                        );
                      "
                    >
                      Customize Your Quote
                    </v-btn> -->
                    <!-- <v-btn
                      v-if="showOptions"
                      x-large
                      color="secondary"
                      class="mb-2 font-weight-bold white--text"
                      @click="showOptions = false"
                    >
                      View Quote
                    </v-btn> -->
                    <!-- <v-btn
                      v-if="showOptions"
                      :disabled="invalid"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      @click="
                        recalculate();
                        clickedFieldGlobal(
                          'Recalculate Quote',
                          33,
                          'Condo Survey v1'
                        );
                      "
                    >
                      Recalculate Quote
                    </v-btn> -->
                  </div>
                  <div>
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      :disabled="!userpresent"
                      @click="
                        next()
                        clickedFieldGlobal(
                          'continuePurchase',
                          110,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Continue To Purchase
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!----------------------DIALOGS--------------------->
              <v-dialog
                v-model="deductibleDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      The deductible is the amount that's deducted from the
                      settlement of a claim. As an example, if a deductible of
                      $500 is selected, the policyholder will pay the first $500
                      for each covered loss under the policy. In some cases
                      there may be a different deductible for certain specified
                      coverages, such as Wind or Hail coverage. If this is the
                      case,the policy will have 2 deductibles: one for Wind/Hail
                      and one for everything else. For more details please refer
                      to the Policy Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        deductibleDialog = false
                        clickedFieldGlobal(
                          `coverages deductibleDialogCloseBtn`,
                          111,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dwellDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      If a covered loss occurs, this is the maximum amount of
                      coverage we will provide to rebuild, repair or replace the
                      portion of the condo premises for which the Insured is
                      responsible. This includes fixtures and improvements
                      within the walls of the condo, while the condo association
                      covers the building and common areas around the building.
                      Please confirm with the condo association (they should
                      have a master policy) what they cover and what the Insured
                      need's to cover. We have included the basic amount of
                      Dwelling Coverage (also referred to as Coverage A), which
                      may be increased for additional premium.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        dwellDialog = false
                        clickedFieldGlobal(
                          `coverages dwellDialogCloseBtn`,
                          112,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="louDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Loss of Use covers the cost of additional living expenses
                      incurred when a covered loss occurs and the home becomes
                      uninhabitable. Reimbursement of these additional living
                      expenses covers the cost of maintaining a normal standard
                      of living while the Insured can't live in their home.
                      <br /><br />

                      The policy automatically includes Loss of Use coverage at
                      20% of the Dwelling Coverage limit (Coverage A).
                      <br /><br />

                      Loss of Use is also referred to as Coverage D. For
                      Additional Information, please refer to the Policy
                      Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        louDialog = false
                        clickedFieldGlobal(
                          `coverages lossOfUseDialogCloseBtn`,
                          113,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="replacementDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Personal Property Coverage protects personal belongings
                      from covered losses up to the limit chosen. They are
                      covered if lost or damaged in the insured's home or car,
                      or even if in the insured's possession while away from
                      home. For Additional Information, please refer to the
                      Policy Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        replacementDialog = false
                        clickedFieldGlobal(
                          `coverages replacementDialogCloseBtn`,
                          114,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="ppDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Personal Property Coverage protects personal belongings
                      from covered losses up to the limit chosen. They are
                      covered if lost or damaged in the insured's home or car,
                      or even if in the insured's possession while away from
                      home. For Additional Information, please refer to the
                      Policy Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        ppDialog = false
                        clickedFieldGlobal(
                          `coverages personalPropertyDialogCloseBtn`,
                          115,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="liabilityDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      This covers injury or damage that the insured causes to
                      the persons or property of others or that happens on the
                      insured property. For example, someone trips and falls
                      while in the home, is badly hurt and sues for damages. For
                      Additional Information, please refer to the Policy
                      Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        liabilityDialog = false
                        clickedFieldGlobal(
                          `coverages liabilityDialogCloseBtn`,
                          116,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="mpDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      This covers medical expenses within three years from the
                      date of a covered accident. This includes reasonable
                      charges for medical, surgical, x-ray, dental, ambulance,
                      hospital, professional nursing, prosthetic devices and
                      funeral services. For Additional Information, please refer
                      to the Policy Documents link under the Quote Details.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        mpDialog = false
                        clickedFieldGlobal(
                          `coverages medicalPaymentDialogCloseBtn`,
                          117,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="alDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      All policies exclude coverage for Animal Liability unless
                      the Animal Liability endorsement has been added. Animal
                      Liability cannot be purchased if there is a prohibited
                      breed of dog, or a dog with a prior bite history, in the
                      household. Prohibited dogs are Akita, Alaskan Malamute,
                      American Staffordshire Terriers, Chow, Dalmatian, Doberman
                      Pinscher, Husky, Pit Bull, Presa Canario (Canary dog),
                      Rottweiler, Staffordshire Bull Terriers, Wolf-dogs and
                      Wolf hybrids, or a mixed breed of any of the ones listed.
                      The amount of coverage chosen must be equal to or less
                      than the Liability limit, but cannot be more than
                      $300,000. Certain restrictions and exclusions apply.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        alDialog = false
                        clickedFieldGlobal(
                          `coverages animalDialogCloseBtn`,
                          118,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="moldDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      This optional endorsement covers certain mold, fungus or
                      rot damage caused by sudden or accidental discharge of
                      water or steam if the loss is not otherwise excluded, from
                      a plumbing, heating, air conditioning, irrigation,
                      automatic fire protective sprinkler system or household
                      appliance. Coverage includes the cost of tearing out and
                      replacing part of a building necessary to repair the
                      system or appliance. We do not cover loss to the system or
                      appliance from which this water or steam escaped or loss
                      caused by continuous or repeated seepage. Earth movement
                      is never covered under this policy, however caused. We
                      cover ensuing loss caused by fire or explosion. Certain
                      restrictions and exclusions apply.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        moldDialog = false
                        clickedFieldGlobal(
                          `coverages moldDialogCloseBtn`,
                          119,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!----------------------------------------MOBILE--------------------------------------->
              <div
                v-if="mobileDevice"
                id="mobileButtons"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      v-if="!showOptions"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      :disabled="!userpresent"
                      @click="
                        customize()
                        clickedFieldGlobal(
                          'coverages customizeYourQuoteMobile',
                          120,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Customize Your Quote
                    </v-btn>
                    <v-btn
                      v-if="showOptions"
                      x-large
                      color="secondary"
                      class="mb-2 font-weight-bold white--text"
                      @click="
                        showOptions = false
                        clickedFieldGlobal(
                          'coverages cancelMobile',
                          121,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="showOptions"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      @click="
                        recalculate()
                        clickedFieldGlobal(
                          'coverages recalculateQuoteMobile',
                          122,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Recalculate Quote
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="
                        next()
                        clickedFieldGlobal(
                          'coverages continueToPurchaseMobile',
                          123,
                          'Condo Survey v1'
                        )
                      "
                    >
                      Continue To Purchase
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-overlay v-if="loading">
                <v-row
                  class="mt-12"
                >
                  <v-col class="d-flex justify-center">
                    <ellipse-progress
                      :loading="loading"
                      :size="180"
                      img-src="src/assets/images/Condo-Icon.png"
                      thickness="5"
                      empty-thickness="3"
                      line-mode="out 5"
                      legend="false"
                      animation="rs 700 1000"
                      font-size="1.5rem"
                      color="#00A1B7"
                      empty-color-fill="transparent"
                      filename="Condo-Icon.png"
                    >
                    </ellipse-progress>
                  </v-col>
                </v-row>
              </v-overlay>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loginErrorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col class="font-italic">
              {{ loginError }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                @click="clearLoginError"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-dialog
      :error-dialog="errorDialog"
      :error-message="error"
    ></error-dialog>
    <otp-verification
      path="src/assets/images/Condo-Icon.png"
      filename="Condo-Icon.png"
    />
  </v-container>
</template>

<script>
  import numeral from 'numeral'
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  export default {
    components: {
      CreateAccountButton
    },
    data () {
      return {
        loginErrorDialog: false,
        show: null,
        user: {
          email: null,
          password: null
        },
        dwellDialog: false,
        moldDialog: false,
        errorDialog: false,
        rateChangeDialog: false,
        deductibleDialog: false,
        louDialog: false,
        replacementDialog: false,
        ppDialog: false,
        liabilityDialog: false,
        mpDialog: false,
        alDialog: false,
        showOptions: false,
        deductibleSelection: '',
        replacementOrACV: 'Replacement',
        ppAmount: '',
        plAmount: '',
        medpmAmount: '',
        alCheckbox: true,
      }
    },
    computed: {
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      loginError () {
        return this.$store.getters.getloginerror
      },
      error () {
        return this.$store.getters.error
      },
      owner () {
        return this.$store.getters.getCondoOwner
      },
      contentsCoverage () {
        if (this.replacementOrACV === 'Replacement') {
          return 'RCC'
        } else {
          return 'ACV'
        }
      },
      coverageList () {
        return [
          {
            coverageCd: 'PP',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.ppAmount,
                },
              },
            ],
            deductible: {
              formatInteger: this.deductibleSelection,
              deductibleAppliesToCd: 'AllPeril',
            },
          },
          {
            coverageCd: this.contentsCoverage,
          },
          {
            coverageCd: 'PL',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.plAmount,
                },
              },
            ],
          },
          {
            coverageCd: 'MEDPM',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.medpmAmount,
                },
              },
            ],
          },
        ]
      },
      loading () {
        return this.$store.getters.getloading
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      maxDiscount () {
        let maxDiscount = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MaxDiscountAdjustmnt'
        )
        if (maxDiscount) {
          return true
        }
        return false
      },
      quote () {
        return this.$store.getters.getCondoQuote
      },
      quotePremium () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0,0.00')
      },
      quoteDeductible () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[1].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      deductibleFormat () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0].FormatInteger[0]
      },
      dwellLimit () {
        let dwell = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'DWELL'
        )
        return numeral(dwell.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      louLimit () {
        let lou = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'LOU'
        )
        return numeral(lou.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      coverages () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage
      },
      discounts () {
        return this.$store.getters.getCondoDiscounts
      },
      replacementCost () {
        if (this.replacementOrACV === 'Replacement') {
          let replacement = this.coverages.find(
            coverage => coverage.CoverageCd[0] === 'RCC'
          )
          return numeral(replacement.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        } else {
          return 'Included'
        }
      },
      ppCost () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return numeral(pp.CurrentTermAmt[0].Amt[0]).format('0,0.00')
      },
      ppLimit () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return numeral(pp.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      ppFormat () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return parseInt(pp.Limit[0].FormatInteger[0])
      },
      plCost () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        if (pl.CurrentTermAmt !== undefined) {
          return numeral(pl.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        } else {
          return 'Included'
        }
      },
      plLimit () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        return numeral(pl.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      plFormat () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        return parseInt(pl.Limit[0].FormatInteger[0])
      },
      medpmCost () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        if (medpm.CurrentTermAmt !== undefined) {
          return numeral(medpm.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        } else {
          return 'Included'
        }
      },
      medpmLimit () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        return numeral(medpm.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      medpmFormat () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        return parseInt(medpm.Limit[0].FormatInteger[0])
      },
      alCost () {
        let animalLiability = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'AnimalLiability'
        )
        if (animalLiability) {
          return numeral(animalLiability.CurrentTermAmt[0].Amt[0]).format(
            '0,0.00'
          )
        }
        return null
      },
      inflationCost () {
        let inflation = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'INFG'
        )
        if (inflation) {
          return numeral(inflation.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        }
        return null
      },
      // hurCost () {
      //   let hurricane = this.coverages.find(
      //     (coverage) => coverage.CoverageCd[0] === 'HURR'
      //   )
      //   if (hurricane) {
      //     return numeral(hurricane.CurrentTermAmt[0].Amt[0]).format('0,0.00')
      //   }
      //   return null
      // },
      moldCost () {
        let mold = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MOLD'
        )
        if (mold) {
          if (mold.CurrentTermAmt !== undefined) {
            return numeral(mold.CurrentTermAmt[0].Amt[0]).format('0,0.00')
          }
          return 'Included'
        }
        return null
      },
      covgACost () {
        let covgA = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'AASPC'
        )
        if (covgA) {
          return numeral(covgA.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        }
        return null
      },
    },
    watch: {
      loginError (newValue) {
        console.log('loginError changed:', newValue)
        this.loginErrorDialog = !!newValue
      },
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      },
    },
    mounted () {
      this.user.email = this.owner.email
    },
    created () {
      console.log('Quote', this.$store.getters.getCondoQuote)
    },
    methods: {
      clearLoginError () {
        this.$store.dispatch('clearLoginError')
        this.loginErrorDialog = false
      },
      loginSubmit () {
        this.$store.dispatch('signApplicantIn', {
          email: this.user.email,
          password: this.user.password
        })
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      async next () {
        try {
          await this.$store.dispatch('condoPreBind')
          if (!this.error) {
            this.$router.push({ path: '/condo/pay' })
          }
        } catch (error) {
          console.error('Error in next method:', error)
        }
      },
      customize () {
        this.showOptions = true
        this.deductibleSelection = parseInt(this.quoteDeductible)
        this.ppAmount = this.ppFormat
        this.plAmount = this.plFormat
        this.medpmAmount = this.medpmFormat
        console.log(this.plAmount)
      },
      recalculate () {
        console.log('Recalculating')
        this.$store.dispatch('getRevisedCondoQuote', this.coverageList)
      },
    },
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}

.top-divider {
  height: 4px;
}

.v-input__control {
  height: 50px !important;
}
</style>
